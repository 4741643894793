import { TempusDominus } from '@eonasdan/tempus-dominus';

export const initDatetimePicker = () => {
	document.querySelectorAll('input.datepicker').forEach((element) => {

		let displayOptions = {
			sideBySide: false,
			components: { clock: false },
			buttons: {
				today: true
			}
		}

		new TempusDominus(element, {
			display: displayOptions,
			localization: {
				format: (element.dataset.format || 'dd/MM/yyyy')
			}
		})
	});
}

document.addEventListener('DOMContentLoaded', () => {
	initDatetimePicker();
});
