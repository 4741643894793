/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Rails from "@rails/ujs";
import 'bootstrap';
import 'bootstrap-table';
import 'scripts/lightbox_bootstrapped'
import 'scripts/applications'
import 'highcharts'
import 'highcharts/modules/exporting'
import 'jquery'
import 'cocoon-js-vanilla'
import 'cocoon-js-vanilla'
import 'scripts/datepicker'

Rails.start()
require('../thredded_imports.erb');
